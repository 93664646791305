

.article-list {
  overflow-y: auto;

  .create-article-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .create-new-article-button {
    font-size: 12px;
  }
}


/**
    These are used by DeleteChartDialog and RenameChartDialog.
    They must be global or else they won't match the elements
    within the modal dialog.
**/
.action-buttons {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  button {
    flex-grow: 1;
    margin: 5px;
  }
}