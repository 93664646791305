

.account-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  align-self: center;
  margin-right: 20px;

  .avatar-text {
    color: white;
    position: relative;
    top: 1px;
    font-size: 14px;
  }
}