@use "../../general/frontend/components/sizes";


.article-view {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;

  &.loading {
    min-height: calc(100vh - sizes.$top-bar-size - sizes.$bottom-bar-size);
  }

  padding-top: 10px;
  padding-left: 0;
  padding-right: 0;

  .MuiPaper-root {
    width: calc(min(100% - 20px, 1000px));
    max-width: 1000px;
  }

  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
}