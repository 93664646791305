
.article-explainer {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  flex-grow: 1;

  padding-top: 10px;
  padding-left: 0;
  padding-right: 0;
  gap: 30px;

  .MuiPaper-root {
    width: calc(min(100% - 20px, 1000px));
    max-width: 1000px;
  }
}


