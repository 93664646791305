.fade-in-out {
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  &.fade-in {
    animation: fadeIn 300ms;
    opacity: 1;
  }

  &.visible {
    opacity: 1;
  }

  &.fade-out {
    animation: fadeOut 300ms;
    opacity: 0;
  }
}
