

.article-section-display {
  text-align: left;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;

  &.plain-text-display {
    p {
      white-space: pre-wrap;
    }
  }

  &.rich-text-display {
    td > p {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  h2 {
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

    text-align: left;
    font-size: 1.3em;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}