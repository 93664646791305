

.article-title-widget {
  text-align: left;

  h1 {
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

    color: #333;
    font-size: 1.5em;
    font-weight: bold;
  }
}