
.ai-proportion-pie-chart {
  width: 180px;
  height: 60px;
  svg {
    text {
      fill: white !important;
      font-size: 0.5rem !important;
      pointer-events: none;

      &:first-child {
        translate: 0px 1px;
      }
      &:last-child {
        translate: -8px 0px;
      }
    }
  }
}
