.explainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .explainer-card {
    width: 100%;
    text-align: center;

    .MuiCardContent-root {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .skip-text {
    color: blue;
    cursor: pointer;
    font-size: 0.7rem;
  }
}
