
.article-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  min-height: 30px;
  span {
    text-decoration: none;
  }
  width: 100%;
  margin: 0;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 4px;
  padding-left: 10px;
  border-bottom: 1px solid #eee;

  &:first-child {
    border-top: 1px solid #eee;
  }

  &.active {
    color: #5018e0;
    background: #fef;
  }

  &.unnamed {
    .article-title {
      color: #7c7c7c;
    }
  }

  &:not(.active) {
    color: #000000;
  }

  .title-icon-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .article-title-wrapper {
      flex-grow: 1;
      flex-shrink: 1;
    }

    .article-title {
      position: relative;
      top: -1px;
      word-break: break-word;
      text-align: left;
    }
  }

  .article-icon-stack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .article-date {
    font-size: 0.50em;
    white-space: nowrap;
  }

  span {
    font-size: 0.75em;
  }

  &:hover {
    background-color: #fdf;
  }
}
