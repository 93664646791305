

.no-charts-text-wrapper {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  .no-charts-text {


  }
}
