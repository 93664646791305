@use "../components/sizes";


.left-side-menu {
  width: calc(max(18vw, 250px));
  border-right: 1px solid #ccc;

  .left-side-menu-content {
    height: calc(100vh - sizes.$top-bar-size);
    position: relative;
    top: 0;
  }
}


.left-side-menu-content {
  width: calc(max(18vw, 250px));

  height: auto;
  position: relative;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .menu-article-list-wrapper {
    padding-top: 30px;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: auto;
    gap: 30px;

    .left-side-menu-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }

    .article-list-header {
      align-self: flex-start;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-left: 10px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .powered-by-articulon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px;
    font-style: italic;
    text-align: center;
    cursor: pointer;
    font-size: 0.9rem;
    span {
      position: relative;
      top: -2px;
    }
    color: #333;
  }
}