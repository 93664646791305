@use "../components/sizes";

.top-bar {
    height: sizes.$top-bar-size;
    border-bottom: 1px solid rgb(229, 234, 242);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #160F36;

    & > div {
        flex-basis: 50%;
    }

    .MuiButtonBase-root.MuiIconButton-root {
        padding-right: 0;
    }

    .logo-area {
        display: flex;
        flex-direction: row;
        align-items: center;


        color: white;
        flex-grow: 0;
        padding: 15px;
        gap: 25px;

        button.MuiButtonBase-root {
            padding-left: 20px;
            padding-right: 30px;
            padding-top: 20px !important;
            padding-bottom: 20px !important;
            margin-right: 0;
            /* Force the menu icon to be above the website title */
            z-index: 1;
        }

        .new-title-wrapper {
            position: relative;
            margin-left: -15px;
            @media screen and (max-width: 899px) {
                margin-left: -60px;
            }
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0;
            color: white;
            text-decoration: none;
            height: sizes.$top-bar-size;
            z-index: 0;
        }

        .new-title {
            font-size: 16px;
            text-align: left;
            line-height: 17.6px;
            font-weight: bold;
        }

        .new-title-decoration {
            transform: scale(0.15);
            width: 50px;
            position: relative;
            top: 3px;
        }

        .new-description-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0;
        }

        .new-description {
            font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
            font-size: 14px;
            text-align: left;
            line-height: 20px;
            font-weight: 300;
            color: #ccc;
        }

        @media screen and (max-width: 400px) {
            .new-description-wrapper {
                display: none;
            }
        }


        .logo-text {
            font-size: 24px;
            font-weight: bold;
            margin-right: 10px;
        }
    }

    .logo-image {
        position: relative;
        height: 50px;
    }

    /*
     * Set the properties for the chart title editor
     */
    .chart-title-editor {
        input {
            color: white;
            caret-color: white;
            font-weight: bold;
            text-align: center;
        }
    }
}